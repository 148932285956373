import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Container from "../new-design/Container"

export default function Hero({ children }) {
  return (
    <section className="w-full mx-auto">
      <div className=" grid h-[94vh]">
        <div className="col-start-1 row-start-1 relative z-10 bg-stone-900/50 h-full w-full">
          <div className="flex justify-between items-center h-full">
            <Container>
              <div className="max-w-lg flex flex-col gap-5">
                <span className="text-sm relative before:absolute before:h-2 before:w-2 before:rounded-full before:bg-orange-600 before:-left-4 before:top-1.5 text-white">
                  Ibiza Experience
                </span>

                <h1 className="font-sans font-semibold text-stone-100">
                  Design your own Ibiza experience
                </h1>

                <p className="text-stone-300">
                  The most beautiful Villas, Exceptional service and memorable
                  experience
                </p>
              </div>
            </Container>
          </div>
        </div>
        <div className="col-start-1 row-start-1 relative z-0 self-auto">
          <StaticImage
            className="object-cover object-center h-[94vh] w-full"
            src="https://assets.website-files.com/5f65635b5ccc19609d778188/5f6b4c88dc1b76035ebdf979_alex-hall-SS-d4nMNwiQ-unsplash.jpg"
            alt="Ibiza High Life"
          />
        </div>
      </div>
    </section>
  )
}
