import React from "react"

import Card from "../property/Card"

export default function PropertiesList({ properties, hideLabel }) {
  return (
    <div className="w-full max-auto">
      <div className="max-w-6xl w-5/6 2xl:w-full mx-auto pb-6">
        {hideLabel !== true && (
          <>
            <h2 className="pt-12 text-center">Our Properties</h2>
            <p className="pb-12 text-center pt-4 uppercase tracking-[0.3em]">
              A selection of our finest villas
            </p>
          </>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2  xl:grid-cols-3 gap-4 ">
          {properties
            .filter(p => p.lowPrice)
            .map((property, key) => (
              <Card
                key={key}
                area={property.area}
                name={property.name}
                highPrice={property.highPrice}
                lowPrice={property.lowPrice}
                highlight={property.highlight}
                slug={`/property/${property.slug.current}/`}
                image={property.images[0].asset.gatsbyImageData}
              />
            ))}
        </div>
      </div>
    </div>
  )
}
