import * as React from "react"

import Layout from "../components/new-design/Layout"
import Seo from "../components/seo"

import Hero from "../components/home/Hero"
import { graphql } from "gatsby"
// import PropertiesList from "../components/home/PropertiesList"
// import SearchProperties from "../components/home/SearchProperties"
import PropertiesList from "../components/home/PropertiesList"
import SearchBar from "../components/search/SearchBar"

export default function IndexPage({ data, location }) {
  // const [doSearch, setDoSearch] = React.useState(false)

  return (
    <Layout>
      <Seo title="Home" pathname={location.pathname} />
      <div>
        <Hero />
        <SearchBar className=" max-w-6xl w-5/6 2xl:w-full mx-auto relative -mt-24 z-20  " />
        <PropertiesList properties={data.allSanityProperty.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allSanityProperty(filter: { active: { eq: true } }) {
      nodes {
        _id
        slug {
          current
        }
        name
        bathrooms
        bedrooms
        area
        description {
          _key
          _type
          children {
            _key
            _type
            marks
            text
          }
          list
          style
        }
        propertyFeatures {
          name
        }
        highlight
        images {
          asset {
            gatsbyImageData
          }
        }
        name
        lowPrice
        highPrice
      }
    }
  }
`
