import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
// import { numberWithCommas } from "../../utils/formatUtil"
import Separator from "../../utils/Separator"

export default function Card({
  slug,
  image,
  name,
  area,
  highlight,
  lowPrice,
  // highPrice,
}) {
  return (
    <div className="bg-white relative overflow-hidden rounded-xl">
      <Link to={slug}>
        {image ? (
          <div className="aspect-video overflow-hidden">
            <GatsbyImage
              image={image}
              className="h-full w-full object-cover"
              alt={name}
            />
          </div>
        ) : (
          <div className="aspect-video overflow-hidden bg-stone-400" />
        )}
      </Link>
      <div className="p-4">
        <div className="font-semibold">
          <div className="flex flex-col">
            <h3 className="text-3xl">{name}</h3>
            <span className="text-sm text-stone-400 font-medium  h-4">
              {area}
            </span>
          </div>
        </div>
        <div className="py-3  h-20 overflow-hidden">
          <div className="font-light text-sm text-stone-400">{highlight}</div>
        </div>
        <div className="w-full py-4">
          <div className="flex items-end justify-between flex-1 h-16 ">
            <div>
              <div>
                <span className="text-stone-400 text-xs">Low Season</span>
                <div className="flex-col space-x-2 items-center">
                  <strong className="text-4xl">
                    {Separator(lowPrice || 0)}
                  </strong>
                  <span className="text-stone-400">/ Night</span>
                </div>
              </div>
            </div>
            <div>
              <Link
                to={slug}
                className="text-xs text-orange-600 bg-orange-50 px-6 py-3 rounded-full"
              >
                View Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
