import React from "react"
// import ReactDatePicker from "react-datepicker"
import { Controller, useForm } from "react-hook-form"

import { addDays, format } from "date-fns"

import "react-datepicker/dist/react-datepicker.css"
import { navigate } from "gatsby-link"
// import PopoverPanel from "../PopoverPanel"
import { connectRefinementList } from "react-instantsearch-dom"
import SearchPanel from "../SearchPanel"
import Container from "../new-design/Container"
// import useSearchHook from "../hooks/useSearchState"

export default function SearchBar({ className, heading }) {
  // const { start, end } = useSearchHook()
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: {
      adults: 4,
      children: 2,
    },
  })

  React.useEffect(() => {
    const currentDate = new Date()

    if (currentDate.getMonth() >= 5) {
      setValue("start", format(addDays(new Date(), 14), "yyyy-MM-dd"))
      setValue("end", format(addDays(new Date(), 21), "yyyy-MM-dd"))
    } else {
      setValue("start", "2022-05-20")
      setValue("end", "2022-05-27")
    }
  }, [])

  const onSubmit = data => {
    const searchData = {
      ...data,
      start: format(data.start, "yyyy-MM-dd"),
      end: format(data.end, "yyyy-MM-dd"),
    }

    const queryString = Object.keys(searchData)
      .map(key => key + "=" + searchData[key])
      .join("&")

    navigate(`/villas?${queryString}`)
  }

  // console.log("start", data)
  // console.log(errors)
  return (
    <div className={className}>
      <h2 className="pb-12">{heading}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bg-white py-4 lg:rounded-full shadow-xl shadow-stone-400/10">
          <div className="pl-5 pr-2">
            <div className="grid grid-cols-12 gap-2 sm:gap-4 md:gap-2">
              <div className="col-span-6  lg:col-span-3 px-2 flex flex-col">
                <label htmlFor="CheckIn" className="text-xs pl-0 md:pl-3">
                  Check in date
                </label>
                <input
                  className=" px-3 text-base flex items-center h-12 outline-none focus:outline-none w-full rounded "
                  // placeholder="date"
                  type="date"
                  {...register("start", { valueAsDate: true })}
                />
              </div>
              <div className="col-span-6 lg:col-span-3 px-2 flex flex-col">
                <label className="text-xs pl-0 md:pl-3" htmlFor="Date-picker">
                  Check out date
                </label>
                <input
                  className=" px-3  text-base flex items-center h-12 outline-none focus:outline-none w-full rounded "
                  // placeholder="date"
                  type="date"
                  {...register("end", { valueAsDate: true })}
                />
              </div>
              <div className="col-span-6  lg:col-span-2 px-2 flex flex-col">
                <label className="text-xs pl-0 md:pl-3">Adults</label>
                <select
                  placeholder="adults"
                  className="input  px-3 flex items-center font-property h-12 outline-none focus:outline-none w-full rounded  "
                  {...register("adults", { valueAsNumber: true })}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
              <div className="col-span-6 lg:col-span-2 px-2 flex-col flex">
                <label className="text-xs pl-0 md:pl-3">Children</label>
                <select
                  className="input  px-3 flex items-center font-property h-12 outline-none focus:outline-none w-full rounded "
                  placeholder="children"
                  {...register("children", { valueAsNumber: true })}
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>

              <div className="col-span-12 lg:col-span-2 px-2 flex flex-col">
                <button
                  className="bg-[#223C4F] text-white text-sm px-2 py-6 w-full h-full rounded-full"
                  type="submit"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

const RefinementList = ({ items, refine, listName }) => (
  <div>
    <h2 className="font-semibold text-lg pb-6">{listName}</h2>
    <ul className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-7 gap-4">
      {items.length === 0 ? (
        <div className="flex justify-center col-span-2 md:col-span-4 xl:col-span-7">
          <svg
            className="animate-spin h-8 w-8 text-black"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      ) : (
        items.map(item => (
          <li className="text-base font-light" key={item.label}>
            <button
              className={
                item.isRefined
                  ? "font-bold flex space-x-4 items-center  outline-none focus:outline-none"
                  : " flex items-center  font-light outline-none focus:outline-none"
              }
              onClick={event => {
                event.preventDefault()
                refine(item.value)
              }}
            >
              <span className="truncate">
                {item.label}
                {/* ({item.count}) */}
              </span>
              {item.isRefined && (
                <span className="h-6 w-6 flex items-center justify-center bg-black text-white text-xs font-bold rounded-full">
                  X
                </span>
              )}
            </button>
          </li>
        ))
      )}
    </ul>
  </div>
)

const CustomRefinementList = connectRefinementList(RefinementList)
